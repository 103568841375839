import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
      <path d="M3.95252 6.07092H15.2662" stroke="#792B97" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.7891 8.54545L15.2639 6.07058L12.7891 3.5957"
        stroke="#792B97"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.8522 11.7272H2.53846" stroke="#792B97" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.01562 9.25195L2.54075 11.7268L5.01562 14.2017"
        stroke="#792B97"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
